import { useGetOffersListQuery } from 'src/entities/offers';
import { DataGroup } from 'src/entities/dicts';
import TermSheetItems from './TermSheetItems';
import TermSheetContextContextProvider from './TermSheetContext';
import TermSheetCompare from './TermSheetCompare';

type Props = {
  group: DataGroup;
  id: number;
  canManage?: boolean;
};

function TermSheet(params: Props) {
  const { group, canManage = true } = params;
  const { data, isFetching } = useGetOffersListQuery(params);

  return (
    <TermSheetContextContextProvider {...params}>
      {group === DataGroup.loanApps && <TermSheetCompare items={data} />}
      <TermSheetItems
        canManage={canManage}
        group={group}
        items={data}
        loading={isFetching}
      />
    </TermSheetContextContextProvider>
  );
}

export default TermSheet;
