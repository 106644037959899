import { useContext } from 'react';
import Button from '@mui/material/Button';
import { OfferItem } from 'src/entities/offers';
import { DataGroup } from 'src/entities/dicts';
import TermSheetRow from './TermSheetRow';
import ListSkeleton from '../ListSkeleton';
import { TermSheetContext } from './TermSheetContext';
import { DataListWrapper } from '../DataList';

type Props = {
  group: DataGroup;
  items?: OfferItem[];
  loading?: boolean;
  canManage?: boolean;
};

function TermSheetItems({ group, items, loading, canManage }: Props) {
  const { showCreateOfferModal } = useContext(TermSheetContext);

  if (loading || !items?.length) {
    return (
      <ListSkeleton loading={loading}>
        No offers yet
        {group === DataGroup.loanApps && canManage && (
          <Button size="small" onClick={() => showCreateOfferModal()}>
            Create term sheet
          </Button>
        )}
      </ListSkeleton>
    );
  }

  return (
    <DataListWrapper showPaging={false}>
      {items?.map((item) => (
        <TermSheetRow
          key={item.id}
          item={item}
          group={group}
          canManage={canManage}
        />
      ))}
    </DataListWrapper>
  );
}

export default TermSheetItems;
