import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import CompareIcon from 'src/assets/icons/compare-icon.svg?react';
import { OfferItem } from 'src/entities/offers';
import { useModal } from 'src/hooks';
import TermSheetCompareModal from './TermSheetCompareModal';

type Props = {
  items?: OfferItem[];
};

function TermSheetCompare({ items }: Props) {
  const [compareOffersModal, showCompareOffersModal] = useModal(
    TermSheetCompareModal,
    { items },
  );

  if (!items?.length) {
    return null;
  }
  return (
    <>
      <Button
        size="small"
        color="secondary"
        sx={{ mb: 2, width: '100%' }}
        onClick={() => showCompareOffersModal()}
      >
        <SvgIcon component={CompareIcon} />
        Compare Term Sheets
      </Button>
      {compareOffersModal}
    </>
  );
}

export default TermSheetCompare;
