import { Response } from 'src/services';
import { arrayToObject } from 'src/utils';
import {
  MakeOfferFormField,
  OfferAcceptanceStatus,
  OfferCompareData,
  OfferCompareItem,
  OfferInitiatorType,
  OfferItem,
  OffersTermSheet,
  OfferValue,
} from './types';
import { DataGroup } from '../dicts';

const mergeOfferParentValues = (
  values: OfferValue[],
  parentValues?: OfferValue[],
) => {
  const parentValuesMap = arrayToObject(
    parentValues,
    MakeOfferFormField.OfferKeyCode,
  );
  return values?.map((value) => ({
    ...value,
    parentValue: parentValuesMap[value.offerKeyCode]?.offerValue,
  }));
};

export const mapOffersData = ({ data }: Response<OffersTermSheet>) =>
  data?.activeOffers.map(({ values, parent, ...offer }) => ({
    ...offer,
    values: mergeOfferParentValues(values, parent?.values),
  }));

export const getMakeOfferReqValues = (values: OfferValue[]) =>
  values.map(({ id, offerKey, offerValue }) => ({
    key: offerKey,
    value: offerValue,
    id,
  }));

export const isMyselfInitiator = (
  group: DataGroup,
  initiatorType: OfferInitiatorType,
) => {
  const isLoanAppsGroup = group === DataGroup.loanApps;
  const isFundingOppsGroup = group === DataGroup.fundingOpps;
  const isBorrower = initiatorType === OfferInitiatorType.Borrower;
  const isCreditor = initiatorType === OfferInitiatorType.Creditor;

  return (isBorrower && isLoanAppsGroup) || (isCreditor && isFundingOppsGroup);
};

export const getAllowedOfferActions = (
  { acceptanceStatusCode, initiatorType }: OfferItem,
  group: DataGroup,
  canManage?: boolean,
) => {
  const isSelfInitiator = isMyselfInitiator(group, initiatorType);
  const isUnderReview =
    acceptanceStatusCode === OfferAcceptanceStatus.UnderReview;

  return canManage && isUnderReview
    ? { canEdit: isSelfInitiator, canRespond: !isSelfInitiator }
    : {};
};

export const getOfferDisplayName = (
  { borrowerCompanyFullName, creditorCompanyFullName, parentId }: OfferItem,
  group: DataGroup,
) => {
  const { name, description } =
    group === DataGroup.loanApps
      ? { name: creditorCompanyFullName, description: 'Debt Investor' }
      : { name: borrowerCompanyFullName, description: 'Borrower Company Name' };

  return {
    primary: parentId ? name : 'Initial offer',
    secondary: parentId && description,
  };
};

export const getOfferCompareData = (data?: OfferItem[]): OfferCompareData => {
  const valuesMap = new Map();
  const items = data?.map(({ values, ...rest }) => {
    const valuesObj = values.reduce(
      (acc, { offerKey, offerKeyCode, offerValue }) => {
        valuesMap.set(offerKeyCode, { id: offerKeyCode, label: offerKey });
        return { ...acc, [offerKeyCode]: offerValue };
      },
      {},
    );
    return { ...rest, ...valuesObj } as OfferCompareItem;
  });
  const values = Array.from(valuesMap.values());

  return { items, values };
};
