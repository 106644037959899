import { useMemo } from 'react';
import {
  getOfferCompareData,
  OfferAcceptanceStatus,
  OfferCompareField,
  OfferCompareItem,
  OfferInitiatorType,
  OfferItem,
} from 'src/entities/offers';
import ModalDialog, { ModalCloseHandler } from '../ModalDialog';
import TermSheetAccStatus from './TermSheetAccStatus';
import HorizontalTable, { HorizontalTableRowItem } from '../HorizontalTable';

type Props = {
  items?: OfferItem[];
  open: boolean;
  onClose: ModalCloseHandler;
};

const initiatorTypeLabels = {
  [OfferInitiatorType.Borrower]: 'Borrower',
  [OfferInitiatorType.Creditor]: 'Creditor',
};

const staticRows: HorizontalTableRowItem<OfferCompareItem>[] = [
  {
    id: OfferCompareField.InitiatorType,
    label: 'Initiator',
    formatter: (value) => initiatorTypeLabels[value as OfferInitiatorType],
  },
  {
    id: OfferCompareField.CompanyName,
    label: 'Company',
    formatter: (_, item) =>
      item?.creditorCompanyFullName || item?.borrowerCompanyFullName,
  },
  {
    id: OfferCompareField.AcceptanceStatusCode,
    label: 'Acceptance Status',
    formatter: (value) => (
      <TermSheetAccStatus value={value as OfferAcceptanceStatus} />
    ),
  },
];

function TermSheetCompareModal({ items: data, ...rest }: Props) {
  const tableData = useMemo(() => {
    const { items, values } = getOfferCompareData(data);
    return { items, rows: [...staticRows, ...values] };
  }, [data]);

  return (
    <ModalDialog
      title="Compare Terms Sheets"
      maxWidth="lg"
      fullWidth={false}
      showCancelBtn={false}
      contentProps={{ sx: { px: 0 } }}
      {...rest}
    >
      <HorizontalTable {...tableData} />
    </ModalDialog>
  );
}

export default TermSheetCompareModal;
